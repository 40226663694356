import React from "react"
import tw, { css } from "twin.macro"
import { RouteComponentProps, Router } from "@reach/router"
import { navigate } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import ReactTooltip from "react-tooltip"
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react"

import { Localization } from "@src/localization"
import CampaignNavigation, {
  NavigationWidth,
} from "@src/components/campaign/navigation"
import ActionBar from "@src/components/campaign/actionBar"
import CustomContainer from "@src/components/customContainer"
import Loading from "@src/components/loading"
import {
  LeftContainer,
  RightContainer,
} from "@src/sections/editCampaign/containers"
import {
  navigationRoutes,
  nestedNavigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import CampaignEmailPreviewContext from "@src/context/campaignEmailPreviewContext"
import { GET_CAMPAIGN, Campaign, UPDATE_CAMPAIGN } from "@src/queries/campaign"
import EditorContext from "@src/context/editorContext"
import EditorTabsComponent from "./EditorTabs/"
import ToolBar from "./toolBar"
import { TemplatePreviewModal } from "@src/components/template/preview"
import SendTestEmailModal from "./sendTestEmail"
import SendTestEmailSuccessModal from "./sendTestEmailSuccess"
import config from "@src/config"

interface EditorProps {
  campaignId?: number
}

const EditorComponent = ({
  campaignId,
}: EditorProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // page content
  const editorContent = React.useContext(Localization).editor

  // tinymce editor
  const {
    setMceEditor,
    setSelectedNode,
    showPreview,
    setShowPreview,
    isDesktopPreview,
    setIsDesktopPreview,
  } = React.useContext(EditorContext)

  // template for preview
  const {
    campaign: previewCampaign,
    setCampaign: setPreviewCampaign,
  } = React.useContext(CampaignEmailPreviewContext)

  // update campaign
  const [updateCampaignMutation, updateCampaignResp] = useMutation(
    UPDATE_CAMPAIGN
  )

  // get campaign
  const getCampaignResp = useQuery(GET_CAMPAIGN, {
    variables: { campaignId },
    skip: !campaignId,
  })

  // campaign data
  const campaign =
    getCampaignResp &&
    getCampaignResp.data &&
    (getCampaignResp.data.campaign as Campaign)

  React.useEffect(() => {
    if (campaign) {
      setPreviewCampaign && setPreviewCampaign(campaign)
    }
  }, [getCampaignResp])

  // function* execAll(str: string, regex: any) {
  //   if (!regex.global) {
  //     console.error(
  //       "RegExp must have the global flag to retrieve multiple results."
  //     )
  //   }

  //   let match
  //   while ((match = regex.exec(str))) {
  //     yield match
  //   }
  // }
  // on form submit
  const onSubmit = async () => {
    //const newReg = /<a [^>]+href="(.*?)"+(.*?)>((.*?)[^book])<\/a>/gim
    const tmpTemplateContent = previewCampaign?.templateContent || ""
    //const matches = execAll(tmpTemplateContent, newReg) || []
    
    // for (const match of matches) {
    //   if (
    //     match[3].toLowerCase().includes("book now") &&
    //     !match[1].includes("CoverKey")
    //   ) {
    //     tmpTemplateContent = match[1].includes("?")
    //       ? tmpTemplateContent.replace(
    //           match[1],
    //           `http://www.4patientcare.ws/v5dn/ws/wsv3p.aspx?CoverKey=${campaign.coverKey}&V4Scenario=1&ReferredBy=Eblast&Source=Eblast&utm_source=eblast&utm_medium=email&utm_campaign=eblast&utm_terms=btnBookNow`
    //         )
    //       : tmpTemplateContent.replace(
    //           match[1],
    //           `http://www.4patientcare.ws/v5dn/ws/wsv3p.aspx?CoverKey=${campaign.coverKey}&V4Scenario=1&ReferredBy=Eblast&Source=Eblast&utm_source=eblast&utm_medium=email&utm_campaign=eblast&utm_terms=btnBookNow`
    //         )
    //   }
    // }
    try {
      // update campaign
      await updateCampaignMutation({
        variables: {
          campaignId,
          body: {
            ...previewCampaign,
            templateContent: tmpTemplateContent,
          },
        },
      })
    } catch (e) {
      console.error(e)
      addToast(
        "errorFields" in e && e.errorFields && e.errorFields.length > 0
          ? "Please fill all the required fields."
          : e.message || "Something went wrong 3",
        { appearance: "error", autoDismiss: true }
      )
      throw e
    }
  }

  // show loader
  if (getCampaignResp.loading || !previewCampaign)
    return <Loading withOverlay={true} />

  return (
    <div css={[tw`flex bg-white flex-col`]}>
      {/* show loading during update */}
      {updateCampaignResp.loading && <Loading withOverlay={true} />}

      {/* Submit campaign modal */}
      <Router>
        <SendTestEmailModal
          path={nestedNavigationRoutes[PageOptions.SENDTESTEMAIL]}
        />
        <SendTestEmailSuccessModal
          path={nestedNavigationRoutes[PageOptions.SENDTESTEMAILSUCCESS]}
        />
      </Router>

      <CustomContainer customCss={tw`flex flex-col`}>
        {/* campaign navigation */}
        <CampaignNavigation width={NavigationWidth.ThreeNine} />

        {/* tooltip */}
        <ReactTooltip
          id="selectDetailsFieldTip"
          place="top"
          effect="solid"
          borderColor="#000"
        />

        {/* page description */}
        <div tw="flex text-black text-xs">{editorContent.pageDescription}</div>

        {/* template preview */}
        <TemplatePreviewModal
          templateContent={previewCampaign.templateContent}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          isDesktopPreview={isDesktopPreview}
          setIsDesktopPreview={setIsDesktopPreview}
        />

        {/* email editing */}
        <div tw="flex mt-3 items-end">
          {/* left container */}
          <LeftContainer
            customCss={[
              tw`w-8/12! p-0! border border-b-0 border-gray-400 mr-4! relative`,
              css`
                height: 510px;
                & .mce-content-body {
                  height: 100%;
                  width: 100%;
                  overflow: auto;
                }
                & .mce-content-body:focus {
                  outline-color: transparent !important;
                }
                .tox .tox-dialog__header {
                  background-color: #ff0000 !important;
                }
              `,
            ]}
          >
            <div tw="p-2 w-full h-full">
              <TinyMCEEditor
                apiKey={config.tinyMceApiKey}
                inline={true}
                initialValue={previewCampaign.templateContent}
                init={config.tinyeMceConfig}
                onEditorChange={(content: any) => {
                  setPreviewCampaign &&
                    setPreviewCampaign({
                      ...previewCampaign,
                      templateContent: content,
                    })
                }}
                onInit={(_event: any, editor: any) => {
                  setMceEditor && setMceEditor(editor)
                }}
                onNodeChange={(event: any) => {
                  // console.log("event", event, typeof event.element)
                  setSelectedNode &&
                    event.element &&
                    setSelectedNode(event.element)
                }}
                onObjectResized={(event: any) => {
                  if (event.target.nodeName == "IMG") {
                    // image width
                    const imgWidth = event.target.style.width

                    if (imgWidth && imgWidth.includes("px")) {
                      const widthInPercent = `${(
                        (parseInt(imgWidth) * 100) /
                        event.target.parentElement.clientWidth
                      ).toFixed(0)}%`

                      event.target.setAttribute("data-mce-style", "")
                      event.target.style.width = widthInPercent
                      event.target.style.height = "auto"
                    }
                  }
                }}
              />
            </div>

            {/* quick toolbar */}
            <ToolBar />
          </LeftContainer>

          {/* right container */}
          <RightContainer
            heading={editorContent.heading}
            description={editorContent.description}
            descriptionCss={tw`text-2xs!`}
            customCss={[
              tw`w-4/12! overflow-auto`,
              css`
                height: 510px;
              `,
            ]}
          >
            <EditorTabsComponent coverKey={(getCampaignResp.data.campaign as Campaign).coverKey.toString()} />
          </RightContainer>
        </div>
      </CustomContainer>

      {/* action bar */}
      <ActionBar
        withBoundaries={true}
        leftContainerCss={tw`w-8/12! justify-between! pr-4!`}
        rightContainerCss={tw`w-4/12!`}
        backBtnIcon={false}
        backBtnText="SEND TEST EMAIL"
        backBtnInfoText="Send a test email to chosen recipient(s)"
        backBtnOnClick={async () => {
          // save and then navigate to send test
          await onSubmit()

          // send test email
          campaignId &&
            navigate(
              createUrl(
                `${navigationRoutes[PageOptions.EDITOR]}/${
                  nestedNavigationRoutes[PageOptions.SENDTESTEMAIL]
                }`,
                {
                  campaignId,
                }
              )
            )
        }}
        closeBtnText="CLOSE"
        closeBtnOnClick={async () => {
          // save and then navigate to send test
          await onSubmit()

          // navigate to campaigns page
          navigate(navigationRoutes[PageOptions.CAMPAIGNS])
        }}
        nextBtnText="COMPLETE CAMPAIGN"
        nextBtnOnClick={async () => {
          await onSubmit()

          campaignId &&
            navigate(
              createUrl(navigationRoutes[PageOptions.SUBMITCAMPAIGN], {
                campaignId,
              })
            )
        }}
        saveBtnOnClick={async () => {
          await onSubmit()
        }}
      />
    </div>
  )
}

export default EditorComponent
