import React from "react"

import { Campaign } from "@src/queries/campaign"

interface CampaignEmailPreviewContextProps {
  campaign: Campaign | null
  setCampaign?: React.Dispatch<React.SetStateAction<Campaign | null>>
}

const CampaignEmailPreviewContext = React.createContext<CampaignEmailPreviewContextProps>(
  {
    campaign: null,
  }
)

export default CampaignEmailPreviewContext
