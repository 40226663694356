import React from "react"

import Layout from "@src/components/layout"
import EditCampaignSection from "@src/sections/editCampaign"

const Edit = (): React.ReactElement => {
  return (
    <Layout>
      <EditCampaignSection />
    </Layout>
  )
}

export default Edit
