import React from "react"
import tw from "twin.macro"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"

import CustomModal from "@src/components/ui/modal"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import CustomButton, { ButtonColors } from "@src/components/ui/button"

const Title = ({ heading }: { heading: string }) => (
  <CustomModal.Title heading={heading} alignCenter>
    {" "}
  </CustomModal.Title>
)

interface SendTestEmailProps {
  campaignId?: number
}

// eslint-disable-next-line no-empty-pattern
const SendTestEmailSuccessModal = ({
  campaignId,
}: RouteComponentProps & SendTestEmailProps): React.ReactElement => {
  const onCancel = () => {
    campaignId &&
      navigate(
        createUrl(navigationRoutes[PageOptions.EDITOR], {
          campaignId,
        })
      )
  }
  return (
    <CustomModal
      title={<Title heading="GREAT JOB!" />}
      isVisible={true}
      onCancel={onCancel}
      width={520}
      footer={
        <CustomModal.Footer heading="Please check your inbox(es) for the test email. ">
          <div tw="flex justify-end">
            <CustomButton color={ButtonColors.white} onClick={onCancel}>
              CLOSE
            </CustomButton>
          </div>
        </CustomModal.Footer>
      }
    >
      <div tw="flex flex-col px-16 py-8 text-black">
        <p css={[tw`text-base text-center`]}>
          A test email has been sent to the email addresses you provided with
          the test patient information selection you requested!
        </p>
      </div>
    </CustomModal>
  )
}

export default SendTestEmailSuccessModal
