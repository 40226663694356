import React from "react"
import tw, { css } from "twin.macro"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { useQuery, useMutation } from "@apollo/client"
import { Form, Input } from "antd"
import { ArrowRightOutlined, CloseCircleFilled } from "@ant-design/icons"
import { useToasts } from "react-toast-notifications"

import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomModal from "@src/components/ui/modal"
import {
  navigationRoutes,
  nestedNavigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import Loading from "@src/components/loading"
import { GET_CAMPAIGN, Campaign } from "@src/queries/campaign"
import { createUrl } from "@src/utils/createUrl"
import InputLabel from "@src/components/ui/inputLabel"
import {
  defaultSendTestVariablesArr,
  defaultSendTestVariables,
  SendTestEmailInput,
  SEND_TEST_EMAIL,
} from "@src/queries/email"

const Title = ({ heading }: { heading: string }) => (
  <CustomModal.Title heading={heading}> </CustomModal.Title>
)

const Footer = ({
  heading,
  isSuccessBtnActive,
  onSuccess,
  onCancel,
}: {
  heading: string
  isSuccessBtnActive: boolean
  onSuccess: () => void
  onCancel: () => void
}) => (
  <CustomModal.Footer heading={heading}>
    <div tw="flex justify-end">
      <CustomButton color={ButtonColors.white} onClick={onCancel}>
        CANCEL
      </CustomButton>

      <CustomButton
        color={ButtonColors.orange}
        disabled={!isSuccessBtnActive}
        onClick={onSuccess}
      >
        SEND TEST EMAIL
      </CustomButton>
    </div>
  </CustomModal.Footer>
)

interface SendTestEmailProps {
  campaignId?: number
}

// eslint-disable-next-line no-empty-pattern
const SendTestEmailModal = ({
  campaignId,
}: SendTestEmailProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // submit campaign
  const [sendTestEmailMutation, sendTestEmailResp] = useMutation(
    SEND_TEST_EMAIL
  )

  // form
  const [form] = Form.useForm()

  // test email array
  const [testEmails, setTestEmails] = React.useState<string[]>([])

  // get campaigns
  const getCampaignResp = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
    },
    skip: !campaignId,
  })

  // campaign
  const campaign: Campaign | null =
    (getCampaignResp &&
      getCampaignResp.data &&
      (getCampaignResp.data.campaign as Campaign)) ||
    null

  // submit campaign
  const sendTestEmail = async () => {
    try {
      if (!campaign) return

      const body: SendTestEmailInput = {
        subject: `Test: ${campaign.subject}`,
        template: campaign.templateContent,
        to: testEmails,
        variables: defaultSendTestVariables,
      }

      // update campaign
      await sendTestEmailMutation({
        variables: {
          body,
        },
      })
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
    }
  }

  const closeModal = () => {
    campaignId &&
      navigate(
        createUrl(navigationRoutes[PageOptions.EDITOR], {
          campaignId,
        })
      )
  }

  // show loading if no campaign
  if (!campaign) return <Loading withOverlay={true} />

  return (
    <>
      {(getCampaignResp.loading || sendTestEmailResp.loading) && (
        <Loading withOverlay={true} />
      )}
      <CustomModal
        title={<Title heading="You’ve requested to send a test email." />}
        isVisible={true}
        onCancel={closeModal}
        footer={
          <Footer
            heading=""
            isSuccessBtnActive={testEmails.length > 0}
            onSuccess={async () => {
              await sendTestEmail()

              campaignId &&
                navigate(
                  createUrl(
                    `${navigationRoutes[PageOptions.EDITOR]}/${
                      nestedNavigationRoutes[PageOptions.SENDTESTEMAILSUCCESS]
                    }`,
                    {
                      campaignId,
                    }
                  )
                )
            }}
            onCancel={closeModal}
          />
        }
        width={700}
      >
        <div tw="flex flex-col px-16 py-4 text-black">
          <p css={[tw`text-sm mb-4`]}>
            Sending a test email is a great way to see what your final email
            will look like.
          </p>

          <p css={[tw`text-sm mb-4`]}>
            To send a test email, enter one or more email addresses below.{" "}
            <span tw="text-orange-600">
              On the test email, all patient and practice variables will be filled with real values and may be different from the default shown below.
            </span>
            .
          </p>

          <div tw="text-sm font-bold pb-3">Default Values:</div>
          <pre
            css={[
              tw`max-h-xxs text-2xs bg-gray-200 py-2 px-4`,
              css`
                line-height: 0.9rem;
              `,
            ]}
          >
            {defaultSendTestVariablesArr.map(([oneVar, oneKey]) => {
              return (
                <div key={oneVar} tw="flex">
                  <div tw="w-48">
                    {"{{"} {oneVar} {"}}"}
                  </div>
                  <div>{oneKey}</div>
                </div>
              )
            })}
          </pre>

          {/* <div css={[tw`flex mt-4 font-bold text-base`]}>
            Campaign: {campaign.name}
          </div> */}
        </div>

        <div
          tw="flex flex-row px-16 py-4 text-black border-t border-gray-400"
          css={css`
            & .ant-select-selector,
            & .ant-input,
            & .ant-input-number,
            & .ant-input-affix-wrapper,
            & .ant-picker,
            & .ant-select-selector {
              border-radius: 50px !important;
              font-size: 0.75rem;
            }
            & .ant-input,
            & .ant-input-number {
              padding: 0 2px !important;
            }
            & .ant-row.ant-form-item {
              margin-bottom: 0px !important;
            }
          `}
        >
          <div tw="flex w-1/2 flex-col pr-2">
            <div tw="text-sm font-bold pb-3">Test Email Recipient List:</div>
            <Form
              name="sendTestEmail"
              form={form}
              onFinish={values => {
                setTestEmails([...testEmails, values.email])

                // reset field
                form.setFieldsValue({ email: "" })
              }}
            >
              {/* left */}
              <div tw="flex w-full flex-col items-center">
                <InputLabel customCss={tw`w-full`}>Email Address</InputLabel>
                <div tw="flex items-center w-full">
                  <Form.Item
                    name="email"
                    tw="mr-2 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please input!",
                      },
                      () => ({
                        validator(_rule, value) {
                          if (testEmails.length >= 5) {
                            return Promise.reject(
                              "Test email can't be sent to more than 5 recipients!"
                            )
                          }

                          const doesExist = testEmails.find(
                            oneTestEmail => value === oneTestEmail
                          )

                          if (!value || !doesExist) {
                            return Promise.resolve()
                          }
                          return Promise.reject("Email address already added!")
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="johndoe@gmail.com"
                      suffix={
                        <ArrowRightOutlined onClick={() => form.submit()} />
                      }
                      type="email"
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
          <div tw="flex flex-col w-1/2 pl-2">
            <InputLabel customCss={tw`w-full`}>Recipient List</InputLabel>
            <div
              tw="text-xs overflow-auto rounded-2xl border border-gray-400 px-2"
              css={css`
                height: 8rem;
              `}
            >
              {testEmails.map(oneTestEmail => (
                <div
                  key={oneTestEmail}
                  tw="py-1 flex justify-between items-center"
                >
                  <span>{oneTestEmail}</span>
                  <span
                    onClick={() => {
                      setTestEmails(testEmails.filter(e => e !== oneTestEmail))
                    }}
                    tw="text-base flex"
                  >
                    <CloseCircleFilled style={{ color: "#777" }} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  )
}

export default SendTestEmailModal
