import React from "react"
import { Router } from "@reach/router"

import Editor from "./editor"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"
import CampaignEmailPreviewContext from "@src/context/campaignEmailPreviewContext"
import { Campaign } from "@src/queries/campaign"
import EditorWrapper from "@src/components/editor/wrapper"

const EditCampaign = (): React.ReactElement => {
  const [campaign, setCampaign] = React.useState<Campaign | null>(null)

  return (
    <CampaignEmailPreviewContext.Provider
      value={{
        campaign,
        setCampaign,
      }}
    >
      <EditorWrapper>
        <Router>
          <Editor path={`${navigationRoutes[PageOptions.EDITOR]}/*`} />
        </Router>
      </EditorWrapper>
    </CampaignEmailPreviewContext.Provider>
  )
}

export default EditCampaign
