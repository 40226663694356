import gql from "graphql-tag"

interface SendTestCampaignVars {
  ID: number,
}

interface SendTestPatientVars {
  ID: number,
  FIRST_NAME: string,
  LAST_NAME: string,
  ADDRESS_1: string,
  ADDRESS_2: string,
  CITY: string,
  STATE: string,
  ZIP: string,
  COUNTRY: string
}

interface SendTestPracticeVars {
  COVERKEY: number,
  NAME: string,
  EMAIL: string,
  ADDR1: string,
  ADDR2: string,
  CITY: string,
  STATE: string,
  ZIP: string,
  PHONE: string
}

interface SendTestLocationVars {
  NAME: string,
  EMAIL: string,
  ADDR1: string,
  ADDR2: string,
  CITY: string,
  STATE: string,
  ZIP: string,
  PHONE: string
}

interface SendTestProviderVars {
  PREFIX: string,
  FIRST_NAME: string,
  LAST_NAME: string,
  DEGREE: string,
  EMAIL: string,
  PHONE: string
}

interface SendTestVariables {
  CAMPAIGN: SendTestCampaignVars
  PATIENT: SendTestPatientVars
  PRACTICE: SendTestPracticeVars
  LOCATION: SendTestLocationVars
  PROVIDER: SendTestProviderVars
}

export const defaultSendTestVariables: SendTestVariables = {
  CAMPAIGN: {
    ID: 1,
  },
  PATIENT: {
    ID: 123,
    FIRST_NAME: "John",
    LAST_NAME: "Doe",
    ADDRESS_1: "999 Test Street",
    ADDRESS_2: "Suite 888",
    CITY: "Bellflower",
    STATE: "California",
    ZIP: "90706",
    COUNTRY: "US"
  },
  PRACTICE: {
    COVERKEY: 456,
    NAME: "20/20 Vision, Inc.",
    EMAIL: "2020_practice@test.com",
    ADDR1: "7777 Main St",
    ADDR2: "Suite 6",
    CITY: "Anaheim",
    STATE: "California",
    ZIP: "92805",
    PHONE: "5555555555"
  },
  LOCATION: {
    NAME: "20/20 Vision - Sylmar",
    EMAIL: "2020_sylmar@test.com",
    ADDR1: "4444 Elm St",
    ADDR2: "Suite 3",
    CITY: "Sylmar",
    STATE: "California",
    ZIP: "91342",
    PHONE: "2222222222"
  },
  PROVIDER: {
    PREFIX: "Dr.",
    FIRST_NAME: "Jane",
    LAST_NAME: "Doe",
    DEGREE: "MD",
    EMAIL: "2020_jane_doe@test.com",
    PHONE: "1111111111"
  }
}

// keys to exclude while printing test variables
const keysToExclude = ["CAMPAIGN.ID", "PATIENT.ID", "PRACTICE.COVERKEY"]
export const defaultSendTestVariablesArr: [string, string][] = []

Object.entries(defaultSendTestVariables).forEach(([oneParentKey, oneParentValueObj]) => {
  Object.entries(oneParentValueObj).forEach(([oneKey, oneValue]) => {
    const combinedKey = `${oneParentKey}.${oneKey}`
    if (!keysToExclude.includes(combinedKey)) {
      defaultSendTestVariablesArr.push([combinedKey, oneValue as string])
    }
  })
})

export interface SendTestEmailInput {
  subject: string
  template: string
  to: string[]
  variables: SendTestVariables
}

export const SEND_TEST_EMAIL = gql`
  mutation SendTestEmail($body: SendTestEmailInput!) {
    testEmail(body: $body)
      @rest(
        path: "/v1/email/test"
        method: "POST"
        bodyKey: "body"
      ) {
      statusCode
    }
  }
`

export interface RemainingCampaigns {
  emailThreshold: number
  thresholdFrequencyType: string
  remaining: number
}

export const REMAINING_CAMPAIGNS = gql`
  query RemainingCampaigns($runAt: Date) {
    remaining(runAt: $runAt)
      @rest(
        path: "/v1/campaigns/remaining?runAt={args.runAt}"
        method: "GET"
      ) {
      emailThreshold
      thresholdFrequencyType
      remaining
    }
  }
`
