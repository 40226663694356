import gql from "graphql-tag"

export enum CampaignStatus {
  Draft = "Draft",
  Submitted = "Submitted",
  Ran = "Ran",
}

export enum Segment {
  Age = "Age",
  Gender = "Gender",
  AppointmentDate = "AppointmentDate",
  PurchasedEyewear = "PurchasedEyewear",
  All = "All",
}

export enum Audience {
  All = "All",
  Specific = "Specific",
}

export const segmentOptions: { label: string; value: string }[] = [
  {
    label: "Age Range",
    value: Segment.Age,
  },
  {
    label: "Appointment Date Range",
    value: Segment.AppointmentDate,
  },
  {
    label: "Gender",
    value: Segment.Gender,
  },
  {
    label: "Eyewear Purchase (Frames/Lenses)",
    value: Segment.PurchasedEyewear,
  },
  {
    label: "All Patients",
    value: Segment.All,
  },
]

export const audienceOptions: { label: string; value: string }[] = [
  {
    label: "All Locations & Providers",
    value: Audience.All,
  },
  {
    label: "Specific Locations & Providers",
    value: Audience.Specific,
  },
]

export enum Gender {
  Male = "Male",
  Female = "Female",
  Other = "Other",
}

export const genderOptions: { label: string; value: string }[] = [
  {
    label: "Male",
    value: Gender.Male,
  },
  {
    label: "Female",
    value: Gender.Female,
  },
  {
    label: "Other",
    value: Gender.Other,
  },
]

export interface CampaignSchedule {
  locKey: string | number
  acctKey: string | number
}

export interface Campaign {
  id: number
  coverKey: number
  selectedTemplateId: string
  name: string
  schedules: string
  description: string
  subject: string
  segment: Segment | null
  minAge: number | null
  maxAge: number | null
  gender: Gender | null
  minAppointmentDate: Date | null
  maxAppointmentDate: Date | null
  purchasedEyewearIn: boolean | null
  templateContent: string
  patientsCount: number | null
  patientsCountedAt: Date | null
  runAt: Date | null
  isFetchingPatientsCount: boolean
  status: CampaignStatus
  createdAt: Date
  modifiedAt: Date
  createdBy: string
  sent: number
  opened: number
  delivered: number
  clicked: number
  unsubscribed: number
  bounced: number
  isUsingLocalPatients: boolean
}

const CAMPAIGN_FRAGMENT = gql`
  fragment Campaign on Campaign {
    id
    coverKey
    selectedTemplateId
    name
    schedules
    description
    subject
    segment
    minAge
    maxAge
    gender
    minAppointmentDate
    maxAppointmentDate
    purchasedEyewearIn
    templateContent
    patientsCount
    patientsCountedAt
    runAt
    isFetchingPatientsCount
    status
    createdAt
    modifiedAt
    createdBy
    sent
    opened
    delivered
    clicked
    bounced
    unsubscribed
    isUsingLocalPatients
  }
`

export const GET_CAMPAIGNS = gql`
  query getCampaigns {
    campaigns @rest(type: "Campaign", path: "/v1/campaigns?PageSize=1000") {
      ...Campaign
    }
  }
  ${CAMPAIGN_FRAGMENT}
`

export const GET_CAMPAIGN = gql`
  query getCampaign($campaignId: number) {
    campaign(campaignId: $campaignId)
      @rest(type: "Campaign", path: "/v1/campaigns/{args.campaignId}") {
      ...Campaign
    }
  }
  ${CAMPAIGN_FRAGMENT}
`

export interface CreateCampaignInput {
  selectedTemplateId: string
  name: string
  schedules: string
  description: string
  subject: string
  segment: string | null
  minAge: number | null
  maxAge: number | null
  gender: string | null
  minAppointmentDate: Date | null
  maxAppointmentDate: Date | null
  purchasedEyewearIn: boolean | null
  templateContent: string
  runAt: Date | null
  status: CampaignStatus
  isUsingLocalPatients: boolean
}

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($body: CreateCampaignInput!) {
    campaign(body: $body)
      @rest(
        type: "Campaign"
        path: "/v1/campaigns"
        method: "POST"
        bodyKey: "body"
      ) {
      ...Campaign
    }
  }
  ${CAMPAIGN_FRAGMENT}
`

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($campaignId: number, $body: CreateCampaignInput!) {
    campaign(campaignId: $campaignId, body: $body)
      @rest(
        type: "Campaign"
        path: "/v1/campaigns/{args.campaignId}"
        method: "PUT"
        bodyKey: "body"
      ) {
      ...Campaign
    }
  }
  ${CAMPAIGN_FRAGMENT}
`

export const SUBMIT_CAMPAIGN = gql`
  mutation SubmitCampaign(
    $campaignId: number
    $body: ValidateLocalPatientDataInput
  ) {
    campaign(campaignId: $campaignId, body: $body)
      @rest(
        path: "/v1/campaigns/{args.campaignId}/submit"
        method: "POST"
        bodyKey: "body"
      ) {
      statusCode
    }
  }
`

export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($campaignId: number) {
    campaign(campaignId: $campaignId)
      @rest(path: "/v1/campaigns/{args.campaignId}", method: "DELETE") {
      statusCode
    }
  }
`

export const GET_CAMPAIGN_PATIENTS_COUNT = gql`
  mutation GetCampaignPatientsCount($campaignId: number) {
    campaign(campaignId: $campaignId)
      @rest(
        path: "/v1/campaigns/{args.campaignId}/patients/count"
        method: "GET"
      ) {
      ...Campaign
    }
  }
  ${CAMPAIGN_FRAGMENT}
`

export const CANCEL_CAMPAIGN_SEND = gql`
  mutation CancelCampaignSend($campaignId: number) {
    campaign(campaignId: $campaignId, body: {})
      @rest(
        path: "/v1/campaigns/{args.campaignId}/cancel"
        method: "POST"
        bodyKey: "body"
      ) {
      statusCode
    }
  }
`
export interface UploadCsvInput {
  File: File
}

export const UPLOAD_PATIENT_FILE = gql`
  mutation uploadPatientFile($body: UploadCsvInput!) {
    csv(body: $body)
      @rest(
        path: "/v1/uploaded-patient-files"
        method: "POST"
        bodyKey: "body"
        bodySerializer: "formData"
      ) {
      CsvCount
      CsvRows
      UploadedPatientFile {
        Id
        CoverKey
        CreatedAt
        CreatedBy
        Url
      }
      Error
      Message
    }
  }
`

export interface ValidateLocalPatientDataInput {
  uploadedPatientFileId: number
  localPatientMapping: {
    [key: string]: string
  }
}

export const VALIDATE_LOCAL_PATIENT_DATA = gql`
  mutation ValidateLocalPatientData($body: ValidateLocalPatientDataInput!) {
    validatePatientData(body: $body)
      @rest(
        type: "LocalPatientDataValidated"
        path: "/v1/validate-local-patient-data"
        method: "POST"
        bodyKey: "body"
      ) {
      errorFile
      errors
    }
  }
`

export interface EmailStats {
  tag: string
  description: string
  start: Date
  end: Date
  resolution: string
  unsubscribed: number
  stats: [
    {
      accepted: {
        incoming: number
        outgoing: number
        total: number
      }
      delivered: {
        smtp: number
        http: number
        optimized: number
        total: number
      }
      failed: {
        temporary: {
          espblock: number
          total: number
        }
        permanent: {
          bounce: number
          webhook: number
          optimized: number
          total: number
        }
      }
      opened: {
        total: number
        unique: number
      }
      clicked: {
        total: number
        unique: number
      }
    }
  ]
}
